/*#region Variables, Reset, General & Body -------------------------------------------------------*/

:root {
  --gray: #2c333b;
  --midgray: #acb3bb;
  --lightgray: #f1f2f3;
  --white: #d0d0de;
  --orange: #ff900f;
  --blue: #143165;
  --wrong: #e46e;
}

* {
  margin: 0;
  padding: 0;
  font-family: Quicksand, Arial, Helvetica, sans-serif;
  box-sizing: border-box;
  line-height: 1.4;
  /* transition: all ease-in-out 200ms; */
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  /* Mobile viewport bug fix */
  /* min-height: -webkit-fill-available; */
}

body {
  font-size: 16px;
}

.none {
  display: none;
}

/*#endregion */

/*#region Maintenance --------------------------------------------------------------------------- */

div.maintenance {
  background-color: var(--wrong);
  padding: 1.5rem 3rem;
  color: white;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  text-wrap: balance;
}

div.container-maintenance {
  margin: 5rem auto;
  width: 40%;
  text-wrap: balance;
  text-align: center;
}

/*#endregion */

/*#region Headers ------------------------------------------------------------------------------- */

header {
  height: 16rem;
  background-size: cover;
  background-image: url('./images/header.jpg'), linear-gradient(90deg, #567 0%, #345 40%, #000 100%);
  background-blend-mode: lighten;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.header-left {
  flex: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 5rem;
}

div.header-left button {
  margin-right: 0.8em;
  font-size: 0.6em;
}

div.header-left img {
  width: 50%;
  margin-top: 1.5em;
}

div.header-right {
  flex: 1;
  margin-right: 10rem;
  white-space: nowrap;
}

img.logo {
  cursor: pointer;
}

/* Specific for report */

div.header-report-left {
  flex: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 5rem;
}

div.header-report-left img {
  width: 50%;
  margin-top: 1.5em;
}

div.header-report-right {
  flex: 1;
  margin-right: 5rem;
  white-space: nowrap;
}

header.report div {
  margin-right: 5rem;
  color: white;
}

header.report div p:first-of-type,
header.report div p:last-of-type {
  font-weight: 700;
}

/*#endregion*/

/*#region Footers ------------------------------------------------------------------------------- */

footer:not(.report) {
  margin: auto auto 0;
  padding: 1em 5em;
  background-color: var(--gray);
  color: var(--white);
  font-size: 0.75em;
  text-align: center;
}

.footer-title {
  font-size: 1.5em;
  font-weight: 700;
}

footer:not(.report) p {
  margin: 0.5em 0;
}

.footer-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5em;
  opacity: 0.75;
  margin: 3em 5em 1em;
  text-align: left;
}

.footer-info-left {
  flex: auto;
}

.footer-info-right {
  flex: 1;
  white-space: nowrap;
  display: flex;
  gap: 0.75rem;
  align-items: start;
}

.footer-info-right .address {
  margin: 0;
}

.footer-info p {
  margin: 0;
}

footer .icon {
  height: 2rem;
}

footer .icon-manufacturer {
  transform: scaleX(1.3);
  margin-top: 0.25rem;
}

footer .icon-udi {
  vertical-align: middle;
  margin-right: 0.25rem;
  margin-top: -1.25px;
}

footer p.udi {
  margin: -0.25rem -2px -0.35rem;
}

/* Specific for report */

footer.report {
  margin: auto 4rem 0;
  padding: 1rem 0;
  border-top: 1px dashed #999;
  font-size: 0.75rem;
}

footer.report img {
  margin-top: 0.5rem;
  width: 150px;
  filter: grayscale(100%);
}

/*#endregion */

/*#region Text ---------------------------------------------------------------------------------- */

h1 {
  font-size: 2.5em;
  letter-spacing: -0.03em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

h1 img {
  margin-left: 0.35em;
  margin-bottom: -0.05em;
  width: 0.75em;
}

h2 img {
  margin-left: 0.4em;
  width: 1em;
}

a.app-title {
  text-decoration: none;
}

p.app-title {
  font-size: 2.8em;
  font-weight: 700;
  color: var(--white);
  letter-spacing: -2px;
  text-shadow: 0 0 0.4em black;
}

p.h4 {
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 1em;
}

p.no-results {
  font-size: 1.5em;
}

p.user-name {
  font-size: 1.25em;
  font-weight: 700;
  color: var(--white);
  margin-bottom: 1em;
}

p.purchased-requests-info {
  margin-top: 2em;
  margin-bottom: 1em;
}

a.relogin-required {
  color: black;
  text-decoration: none;
}

/*#endregion */

/*#region Buttons ------------------------------------------------------------------------------- */

button,
.button {
  background-color: var(--gray);
  color: #fffe;
  border: 0;
  margin: 0.5em 0.5em 0.5em 0;
  padding: 0.6em 1em;
  font-size: 0.9em;
  cursor: pointer;
  width: max-content;
}

.btn-selected {
  color: var(--orange);
}

.btn-danger {
  background-color: darkred;
  color: #fffe;
  margin: 0;
}

.btn-warning {
  background-color: var(--blue);
  color: #fffe;
  margin: 0;
}

a.button {
  text-decoration: none;
}

/*#endregion */

/*#region Navigation ---------------------------------------------------------------------------- */

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

nav a,
nav button {
  color: var(--white);
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.7em;
  text-shadow: 0.2em 0 0.4em black;
  transition: all ease-in-out 200ms;
}

nav a:hover,
nav button:hover {
  color: var(--orange);
  text-decoration: underline;
}

nav button {
  background-color: transparent;
  padding: 0;
}

nav button:hover {
  cursor: pointer;
}

/*#endregion */

/*#region Structure (container...) */

div.container {
  margin: 2rem 5rem;
}

.filters-show {
  max-height: 100vh;
  opacity: 1;
  transition: max-height 500ms ease, opacity 500ms ease, margin-bottom 2500ms ease;
}

.filters-hide {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 200ms ease, opacity 200ms ease, overflow 200ms ease;
}

/*#endregion */

/*#region Tables for entities (patients and users) ---------------------------------------------- */

table.entity {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

table.entity thead {
  background-color: var(--midgray);
  text-align: left;
}

table.entity td:not(.td-flex) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

table.entity .td-flex {
  display: flex;
  align-items: center;
}

table.entity .truncated {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

table.entity .th-12pc {
  width: 12%;
}

table.entity .th-10pc {
  width: 10%;
}

table.entity .th-8pc {
  width: 8%;
}

table.entity .th-6pc {
  width: 6%;
}

table.entity .th-5pc {
  width: 5%;
}

table.entity td {
  padding: 0.25em 1em;
}

table.entity th {
  padding: 0.5em 1em;
}

table.entity tr:nth-child(even) {
  background-color: var(--lightgray);
}

table.entity button {
  font-size: 0.6em;
  margin: 0.2em;
  padding: 0.4em 0.6em;
}

/*#endregion */

/*#region Cells (for patients and user) --------------------------------------------------------- */

.cell {
  margin-bottom: 1.5em;
}

.table-cell {
  background-color: #f0f3f6;
  padding: 0.5em;
  width: 100%;
}

.table-cell-output {
  background-color: #e0e3e6;
  padding: 0.5em;
  width: 100%;
}

.td-cell-label {
  font-size: 0.9em;
  width: 175px;
  white-space: nowrap;
  padding-right: 2em;
}

.td-cell-value {
  font-weight: 700;
  padding-top: 0.05em;
  padding-bottom: 0.05em;
}

.table-conf .td-cell-value {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

/* Cells specific for patients */

.td-patient-output {
  font-weight: 700;
}

.td-patient-pending {
  color: brown;
}

.td-patient-legend {
  color: #888;
  font-size: 0.7em;
}

/*#endregion */

/*#region Forms --------------------------------------------------------------------------------- */

div.form-box {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5em 1em;
  align-items: center;
  margin-bottom: 1em;
}

div.form-box label,
div.form-box p {
  white-space: nowrap;
}

div.wrong-login {
  background-color: var(--wrong);
  font-size: 0.9em;
  padding: 0.6em 1em;
}

/*#endregion */

/*#region Report -------------------------------------------------------------------------------- */

div.report-section-header {
  margin: 3rem -1rem 1rem;
  font-size: 1.1rem;
  font-weight: 700;
  padding: 0em 1em;
  line-height: 1.6;
  color: white;
  background-color: #667;
  text-transform: uppercase;
  position: relative;
}

div.report-section-header::before {
  content: '';
  display: block;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 20px;
  height: 20px;
  z-index: -1;
  background-color: #f59100;
}

div.report-section-header-highlight {
  background-color: #335;
}

td.report-info-text {
  padding-right: 1rem;
}

td.report-patient-value {
  font-weight: 700;
  padding-right: 2rem;
}

td.report-patient-legend {
  color: #888;
  font-size: 0.8rem;
}

table.report-result {
  width: 100%;
}

td.report-prob-text {
  white-space: nowrap;
  padding-right: 1rem;
}

td.report-prob-value {
  white-space: nowrap;
  font-weight: 700;
  padding-right: 1rem;
}

td.report-prob-chart {
  width: 99%;
}

td.report-prob-chart div {
  height: 15px;
  border-radius: 2px;
}

td.report-accuracy {
  color: #888;
  font-size: 0.8rem;
  padding-top: 0.3rem;
  padding-bottom: 0.6rem;
}

div.report-signature {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-around;
  align-items: center;
  margin-top: 4rem;
}

div.report-signature div {
  padding: 0.4em 1em 0.3em;
  font-size: 0.8rem;
  line-height: 1.4;
  background-color: #667;
  color: white;
  text-transform: uppercase;
  text-align: center;
}

div.report-download {
  display: flex;
  justify-content: center;
  margin-top: 2em;
}

/*#endregion */

/*#region Legal --------------------------------------------------------------------------------- */

.legal h2 {
  display: flex;
  align-items: center;
}

.legal h2 {
  margin-bottom: 0.5em;
}

.legal p + h2 {
  margin-top: 1em;
}

.legal p {
  margin: 1.25em 0;
}

/*#endregion */
