input,
textarea,
select {
  border: 0;
  background-color: #f0f3f6;
  height: 2em;
  line-height: 2em;
  font-size: 1em;
  padding: 0.1em 0.5em;
}

textarea {
  height: 6em;
}

input[type='text'],
input[type='email'],
input[type='password'] {
  width: min(400px, 50%);
}

input[type='number'] {
  width: 100px;
}

input[type='radio'],
input[type='checkbox'] {
  transform: scale(1.25);
  vertical-align: middle;
  margin-right: 1.5em;
  margin-bottom: 0.1em;
  opacity: 0.8;
  accent-color: var(--orange);
}

fieldset {
  border: 0;
  background-color: #f0f3f6;
  padding: 0.1em 0.5em;
  height: 2em;
  width: max-content;
  display: inline;
}

legend {
  color: white;
  font-size: 0;
}

fieldset label {
  margin-right: 0.5em;
}

/* Disabled elements */

label.disabled {
  opacity: 0.55;
}

input:disabled {
  color: rgba(0, 0, 0, 0.55);
}

input[type='radio'][disabled]:checked:after {
  position: relative;
}

input[type='radio'][disabled]:checked:after {
  position: absolute;
  transform: scale(calc(1 / 1.4)) translate(-20%, 70%);
  content: '';
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: var(--gray);
}

/* Input type file hack */

input[type='file']::-webkit-file-upload-button {
  width: 0;
  visibility: hidden;
}

input[type='file'] {
  padding: 0;
  color: #fff;
  background-color: #fff;
}

input[type='file']::before {
  content: 'Choose file';
  display: inline-block;
  background-color: var(--gray);
  color: #fffe;
  border: 0;
  margin: 0;
  padding: 0 1em;
  font-size: 0.9em;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

/* Validation */

span.validation {
  padding-left: 0.6em;
  font-size: 0.9em;
  color: var(--wrong);
}

span.validated {
  display: none;
}

input:valid + span.validation,
input:disabled + span.validation {
  display: none;
}

input:invalid + span.validation {
  display: inline;
}
